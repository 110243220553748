import * as React from 'react'
import { Link } from 'gatsby'
import { AuthLayout } from '../components/AuthLayout'
import Button from '../components/Button'
import { TextField } from '../components/Fields'
import CafeZincanLogo from '../../assets/svgs/CafeZincanLogo.svg'

const Login = () => {
  return (
    <>
      <AuthLayout>
        <div className="flex flex-col">
          <Link href="/" aria-label="Home">
            <CafeZincanLogo />
          </Link>
          <div className="mt-20">
            <h2 className="text-lg font-semibold text-gray-900">
              Sign in to your account
            </h2>
            <p className="mt-2 text-sm text-gray-700">
              Don’t have an account?{' '}
              <Link
                href="/waitinglist"
                className="font-medium text-blue-600 hover:underline"
              >
                Join the wait list
              </Link>
            </p>
          </div>
        </div>
        <form action="#" className="mt-10 grid grid-cols-1 gap-y-8">
          <TextField
            label="Email address"
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
          />
          <TextField
            label="Password"
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
          />
          <div className="pb-64">
            <Button
              type="submit"
              variant="solid"
              color="blue"
              className="w-full"
            >
              <span>
                Sign in <span aria-hidden="true">&rarr;</span>
              </span>
            </Button>
          </div>
        </form>
      </AuthLayout>
    </>
  )
}

export default Login;

export const Head = () => <title>Café Zincan Sign In</title>;
